import { environment } from "../../../environments/environment";

export class AppConstants {
    public static readonly apiUrls = {
        homePageCategories: '/categories',
        getProductsList: 'products/search',
        pdpReferenceDetails: 'products/<productCode>/references',
        contactusdetails: '/sgre/contactrequest',
        getBulkOrder: 'products/check',
        getSavedCarts: 'cart/legalentities',
        getFilterSavedCarts: 'legalentitiesForCarts',
        createCart: 'cart/createCart',
        getCarts: 'carts/',
        cartGroup: 'cartGroup/',
        getDuplicateCarts: '/clonesavedcart',
        deleteCart: '/entries/',
        updateCart: '/entries/',
        getCartByLegalEntity: 'cart/legalentities',
        getOrderHistory: 'orders/search',
        getUserLegalEntity: 'user/user-profile',
        createNewCart: 'cart/newCart',
        activeCart: '/setactivecart',
        addToCart: 'carts/',
        usersdetails: 'customerData',
        legalEntities: 'legalentities',
        updateAlternativeEmail: '/b2bcustomers/update/',
        b2bCustomers: '/b2bcustomers/',
        users: 'users/',
        checkout: '/checkout',
        invieUsrCompany: 'companies',
        postInviteUserInternal: 'inviteInternalUser',
        postinvitexternaluser: 'inviteExternalUser',
        getCountries: '/countries',
        submitRequestAccess: '/sgre/requestaccess',
        getCartBulkOrder: 'cart/bulkorder',
        deletewholeorder: 'cancelOrder',
        getCartgroup: 'cartGroup/fetchcartgroups',
        orders: 'orders/',
        cancel: 'cancel',
        cart: 'cart/',
        createFromOrder: 'createFromOrder',
        powerbicharts: 'powerbicharts',
        setDateAndLanguage: 'setdateandlanguage'
    }

    public static routeUrls = {
        login: '/homepage',
        home: '/homepage',
        plp: '/product-search',
        bulkOrder: '/bulk-order',
        pdd: '/product-details',
        contactUs: '/contact-us',
        cart: '/cart',
        manageCarts: '/saved-carts',
        checkout: '/checkout',
        orderHistory: '/order-history',
        orderDetails: '/order-details',
        orderRequest: '/order-requests',
        myProfile: '/myprofile',
        requestAccess: '/request-access',
        userManagement: 'user-management',
        inviteUser: '/user-management/invite-user',
        users: '/user-management/users',
        userProfile: '/user-management/user-profile',
        accessRequest: '/user-management/access-requests',
        termsOfUse: '/terms-of-use',
    }

    // faq/products
    public static breadcrumbUrls = {
        // 'homepage': 'Home',
        'product-search': 'Product Search',
        'product-details': 'Product Details',
        'Electrical': 'Product Details',
        'Mechanical': 'Product Details',
        'Hydraulic': 'Product Details',
        'Others': 'Product Details',
        'Large-Components': 'Product Details',
        'Top-featured-products': 'Product Details',
        'bulk-order': 'Bulk Order',
        'checkout': 'Checkout',
        'saved-carts': 'Manage Carts',
        'cart': 'Cart',
        // top feature products,
        'myprofile': 'My Profile',
        'request-access': 'Request Access',
        'invite-user': 'Invite User',
        'users': 'Users',
        'user-profile': 'User Profile',
        'access-requests': 'Access Requests',
        'contact-us': 'Contact Us',
        'faq': 'FAQ',
        'quotations': 'Quotations',
        'orders': 'Orders',
        'products': 'Products',
        'accessrequests': 'Access Requests',
        'terms-of-use': 'Terms Of Use',
        'order-requests': 'Order Requests',
        'order-details': 'Order Details',
        'order-history': 'Order History',
        'user-management': 'User Management',
        'purchase-terms-and-conditions': 'Purchase Terms and Conditions',
        'cookie-policy':'Cookie Policy',
        'privacy-policy':'Privacy Policy',
    }

    public static LocalStorageKeys = {
        spartacus: 'spartacus⚿⚿',
        currency: 'spartacus⚿⚿currency',
        cart: 'spartacus⚿sgre⚿cart',
        language: 'spartacus⚿⚿language',
        auth: 'spartacus⚿⚿auth',
        anonymous: 'spartacus⚿⚿anonymous-consents',
        userActivity: 'user_last_activity',
        msalInfo: 'msalData',
        redirectUrl: 'redirectUrl',
        bookmarkScenario: 'bookmarkScenarioFlag'
    }

    public static SessionStorageKeys = {
        userLegalEntities: 'userLegalEntities',
        defaultLegalEntity: 'defaultLegalEntity',
        userRoles: 'userRoles',
        dateFormat: 'dateFormat',
        selectedLanguage: 'selectedLanguage',
        query: 'query',
        cartId: 'cartId',
        carts: 'carts',
        activeCart: 'activeCart',
        paramsCartGroupId: 'paramsCartGroupId',
        checkoutCartGroupId: 'checkoutCartGroupId',
        cartCode: 'cartCode',
        secondaryCartCode: 'secondaryCartCode',
        userProfileData: 'userProfileData',
    }

    public static productCategories = {
        largeComponents: '',
        topFeatured: ':name-asc:allCategories:TOP+FEATURED+PRODUCTS',
        electrical: ':name-asc:allCategories:ELECTRICAL',
        hydraulic: ':name-asc:allCategories:HYDRAULIC',
        mechanical: '',
        consumables: '',
        others: ''
    }

    public static securedPages = {
        pdp: 'PDP',
        bulkOrder: this.routeUrls.bulkOrder,
        cart: this.routeUrls.cart,
        manageCarts: this.routeUrls.manageCarts,
        checkout: this.routeUrls.checkout,
        orderHistory: this.routeUrls.orderHistory,
        orderDetails: this.routeUrls.orderDetails,
        orderRequest: this.routeUrls.orderRequest,
        myProfile: this.routeUrls.myProfile,
        accessRequest: this.routeUrls.accessRequest,
        inviteUser: this.routeUrls.inviteUser,
        userProfile: this.routeUrls.userProfile,
        users: this.routeUrls.users,
    }

    public static endPointUrls = {
        oAuth: `${environment.siteUrls.getBaseUrl}/authorizationserver/oauth/token`,
        revoke: `${environment.siteUrls.getBaseUrl}/authorizationserver/oauth/revoke`
    }

    public static expiredTokens = {
        JWT: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Inp4ZWcyV09OcFRrd041R21lWWN1VGR0QzZKMCJ9.eyJhdWQiOiJiOWJlNmM2NS1lYzQ0LTQ1ZmYtODMwOS00YjYxYjYwNjJiZjUiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vMGFkOTQyMTktNmFmNS00NzRlLTk5ZDAtNjBmOTE4OGYzZmNlL3YyLjAiLCJpYXQiOjE3MzMyODgxNDYsIm5iZiI6MTczMzI4ODE0NiwiZXhwIjoxNzMzMjkyMDQ2LCJhaW8iOiJBVVFBdS84WUFBQUFxWWNoQWNjUTJ5UFhwRFNTT2JQOExVNG5VTW1oeEV5cGV2bHlxWUxNelhSaThpc2RkUTJMcURWbGVOeStxYmJOYTA2QzMzTXB0NkpJSkQ4Q20xaEtNdz09IiwiYXV0aF90aW1lIjoxNzMzMjg4NDMxLCJlbWFpbCI6InRlc3QtdXNlci5wdXJjaGFzZXItcmVxdWVzdGVyQGIyYmRldi5zZ3JlLm9uZSIsImdyb3VwcyI6WyJmNWI3NTIxMC1lMGMzLTRiYzktOGFlYi0xMjJjOTRiOGFmMzMiLCIzNmI0NTY1ZC00YTU3LTQ1MzMtYTgwMi04OTgzYjllN2E5ZWMiXSwiaWRwIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvMTBlM2U5NDQtMjZhMi00MDRiLThkMDItZDU2MzIyOThjZDc2LyIsImxvZ2luX2hpbnQiOiJPLkNpUTJPV1V3TjJZM05TMHpNRGxpTFRRMVlqUXRPVGsxWkMwMU1EZzVPVFJtTXpnM1kyRVNKREV3WlRObE9UUTBMVEkyWVRJdE5EQTBZaTA0WkRBeUxXUTFOak15TWprNFkyUTNOaG90ZEdWemRDMTFjMlZ5TG5CMWNtTm9ZWE5sY2kxeVpYRjFaWE4wWlhKQVlqSmlaR1YyTG5ObmNtVXViMjVsSVBRQiIsIm5hbWUiOiJUZXN0LVVzZXIgUHVyY2hhc2VyLVJlcXVlc3RlciIsIm5vbmNlIjoiMDE5MzkwMDktY2VlZi03M2FmLWIzMzYtOTBhZThjMjBjZTM1Iiwib2lkIjoiZjI2MGZkNzktZWI5OC00MTMxLWFjNDItMjZhMWRjNDM0ODQ4IiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdC11c2VyLnB1cmNoYXNlci1yZXF1ZXN0ZXJAYjJiZGV2LnNncmUub25lIiwicmgiOiIxLkFWOEFHVUxaQ3ZWcVRrZVowR0Q1R0k4X3ptVnN2cmxFN1A5Rmd3bExZYllHS19WZkFITmZBQS4iLCJzdWIiOiIxWnNiMmMwZDRBeTQ4RDQ2eXhTYmVZS0gySUZGa0RhWU4yOUlkb0pLUDdzIiwidGlkIjoiMGFkOTQyMTktNmFmNS00NzRlLTk5ZDAtNjBmOTE4OGYzZmNlIiwidXRpIjoiamduYU9OUWluVUNWNGVTXzNnU3VBQSIsInZlciI6IjIuMCIsIndpZHMiOlsiMTNiZDFjNzItNmY0YS00ZGNmLTk4NWYtMThkM2I4MGYyMDhhIl19.bNv0iQlhSwJbiX3RTCEKYgILBZDFV3TEJm5_cF3WgnB8vFP3JJYJFJUxZoE5pTIvsbWnhXxUhw0lWhAzwkYSCbQ6npivm1NCEpcPticYs8KXFCoYmyOlxUH5JBxcix4MatIFosoJGsr4xnOs4e5ROTqifsdf9F2tNO_vkePTYxtvQ3HklpBtCROw86L0k8x3YAwWlllIu8B5aCXuubMHKTwDoIkHz-5_weB2HhEjxkm_eut42VnpObf4eQkCBGDh2DsAd0-EYh1rTJsticc_7ttEoZKPxYuWzv5qta8dfrdzV96GU-k22gBnu4MBtdXzD25BAzgLe029zhrB1h0MEA'
    }
}

export enum UserRoles {
    REQUESTER = 'Requester',
    PURCHASER = 'Purchaser',
    CUSTOMER_ADMIN = 'Customer Admin',
    SALES_REP = 'Sales Rep',
    SGRE_ADMIN = 'SGRE Admin'
}